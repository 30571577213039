<template>
    <div class="footer">
        <div class="row">
            <div class="col-12">
                <div class="footer__info text-center">
                    <a href="mailto:hello@qolab.eu" class="mx-3 mr-lg-5">{{ mail }}</a>
                    <a href="tel:+421917868776" class="mx-3 d-block d-md-inline">
                        {{ phoneNumber }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data: () => {
        return {
            mail: 'hello@qolab.eu',
            phoneNumber: '+421 917 868 776'
        }
    }
}
</script>

<style lang="scss" src="./index.scss" />
