<template>
    <div class="home">
        <div class="home__content">
            <h1 class="content__title text-center pb-md-5 pb-sm-2">qolab</h1>
            <div class="content__typed" :key="$i18n.locale">
                <vue-typed-js
                    :strings="$t(titles)"
                    :backSpeed="20"
                    :backDelay="800"
                    :contentType="'html'"
                    :showCursor="false"
                    loop
                >
                    <h3 class="typing" />
                </vue-typed-js>
            </div>
            <div class="text-center pt-md-5 pt-sm-2">
                <a class="content__subtitle" href="mailto:hello@qolab.eu">{{ $t('motto') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data: () => {
        return {
            titles: 'titles'
        }
    }
}
</script>

<style lang="scss" src="./index.scss" />
