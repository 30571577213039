import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import VueTypedJs from 'vue-typed-js'

// Typed JS
Vue.use(VueTypedJs)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    i18n,
    render: (h) => h(App)
}).$mount('#app')
