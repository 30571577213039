<template>
    <div class="header">
        <div class="d-flex flex-row-reverse mx-5">
            <span class="header__language" @click="changeLanguage(nextLanguage)">
                {{ nextLanguage }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data: () => {
        return {
            languages: ['en', 'sk'],
            currentLanguage: 'sk',
            nextLanguage: 'en'
        }
    },
    methods: {
        changeLanguage(language) {
            this.$i18n.locale = this.nextLanguage
            this.nextLanguage = this.currentLanguage
            this.currentLanguage = language
        }
    }
}
</script>

<style lang="scss" src="./index.scss" />
