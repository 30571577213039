<template>
    <div id="app">
        <Header />
        <Home />
        <Footer />
    </div>
</template>

<script type="text/javascript">
import Header from '@/components/Header/index.vue'
import Footer from '@/components/Footer/index.vue'
import Home from '@/views/Home/index.vue'

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        Home
    }
}
</script>
