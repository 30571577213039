import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'sk',
    fallbackLocale: 'en',
    sync: true,
    messages: {
        en: require('./locales/en.json'),
        sk: require('./locales/sk.json')
    }
})

export default i18n
